import Link from "next/link";
import styles from "./style.module.scss";

function HeaderLogo() {
  return (
    <div className={styles.logoContainer}>
      <Link href="/">
        <a>
          {/* We've got two different images here so that we can hide the brand mark on mobile */}
          <span className={styles.logo}>
            <picture>
              <img
                src="https://d2uvg1ukogcips.cloudfront.net/next-assets/public/brand-mark.svg"
                alt="squarefoot"
                title="SquareFoot"
                width="30"
                height="28"
              />
            </picture>
          </span>
          <span className={styles.logoText}>
            <picture>
              <img
                src="https://d2uvg1ukogcips.cloudfront.net/next-assets/public/brand-text.svg"
                alt="squarefoot"
                title="SquareFoot"
                height="15"
                width="154"
                className={styles.logoText}
              />
            </picture>
          </span>
        </a>
      </Link>
    </div>
  );
}

export default HeaderLogo;
