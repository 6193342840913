import cn from "classnames";
import PropTypes from "prop-types";
import { MUTATION_NAMES } from "util/constants";
import React, { useState, useEffect } from "react";
import LoadingDots from "components/componentLibrary/LoadingDots";
import { useMutation, useApolloClient } from "@apollo/client";
import gql from "graphql-tag";
import { track, eventCategories, analyticsEvents } from "util/analytics";
import { useCurrentUser } from "hooks";

import Modal from "../SplitContentModal";
import styles from "./style.module.scss";

const SIGN_IN_MUTATION = gql`
  mutation ${MUTATION_NAMES.SIGN_IN}($email: String!, $password: String!) {
    signIn(input: { email: $email, password: $password }) {
      currentUser {
        id
        uuid
        email
        phone
        isAdmin
        lastName
        isRenter
        isBroker
        firstName
        e164Phone
        isVerified
        companyName
        displayName
        hasPassword
        salesforceId
        featureFlags
        profilePicURL
        completedUserTasks {
          id
          task
        }
        confirmed
        cityOfInterest
        unconfirmedEmail
        tours {
          id
          date
        }
        latestCampaign {
          id
          uuid
          listings {
            id
          }
          currentActiveRequirements(setByUser: true) {
            id
            neighborhoods {
              id
              name
            }
            minPsf
            maxPsf
            minSquareFeet
            maxSquareFeet
            minMonthlyBudget
            maxMonthlyBudget
            numberOfEmployees
            minSqft: minSquareFeet
            maxSqft: maxSquareFeet
            maxPricePerSquareFoot: maxPsf
            minPricePerSquareFoot: minPsf
            maxMonthlyPrice: maxMonthlyBudget
            minMonthlyPrice: minMonthlyBudget
            leaseTerm
            areas {
              id
              externalId
              name
            }
            market {
              id
              name
              externalId
              position {
                lat
                lng
              }
            }
          }
        }
      }
    }
  }
`;

const SURVEY_VISIT_EMAIL_MUTATION = gql`
  mutation SendSurveyVisitEmailMutation($campaignId: ID, $surveyId: ID) {
    sendSurveyVisitEmail(
      input: { campaignId: $campaignId, surveyId: $surveyId }
    ) {
      success
    }
  }
`;

const SignInModal = ({
  onClose,
  customCTA,
  onSuccess,
  eventLabel,
  existingEmail,
  customSideContent,
  createAccountLink,
}) => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState(existingEmail);
  const [loggingIn, setLoggingIn] = useState(false);
  const [signIn, { loading, error: loginError }] =
    useMutation(SIGN_IN_MUTATION);
  const [onClientLoginNotifyBroker] = useMutation(SURVEY_VISIT_EMAIL_MUTATION);
  const { login } = useCurrentUser();
  const client = useApolloClient();

  useEffect(() => {
    track(analyticsEvents.open, {
      category: eventCategories.modal,
      label: eventLabel,
    });
    // disabling because empty array is intended for component did mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const trackClick = () =>
    track(analyticsEvents.forgot_password_link, {
      category: eventCategories.account,
      label: eventLabel,
    });

  const handleClose = (evt) => {
    evt.stopPropagation();
    track(analyticsEvents.close, {
      category: eventCategories.modal,
      label: `Sign In, ${eventLabel}`,
    });
    onClose();
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setLoggingIn(true);
    // Currently there is no log out functionality on miyagi-next. Once there is, this client.resetStore() should be placed in there.
    client.resetStore();
    try {
      const {
        data: {
          signIn: { currentUser },
        },
      } = await signIn({ variables: { email, password } });
      if (currentUser) {
        await login();
        track(analyticsEvents.sign_in, {
          category: eventCategories.account,
          label: eventLabel,
        });
        if (!currentUser.isBroker) {
          onClientLoginNotifyBroker({
            variables: {
              campaignId: currentUser.latestCampaign.id,
            },
          });
        }
        if (onSuccess) {
          onSuccess(currentUser);
        } else {
          onClose();
        }
        setLoggingIn(false);
      } else {
        setLoggingIn(false);
      }
    } catch {
      setLoggingIn(false);
      // Failed login, do nothing on failed log. Needed to prevent exception being rethrown to client.
    }
  };

  return (
    <Modal
      onClose={handleClose}
      sideContent={
        <span className={styles.sideContent}>
          {React.cloneElement(customSideContent, {
            className: styles.customSideContent,
          })}
        </span>
      }
    >
      <div className={styles.cta}>{customCTA}</div>
      <form className={styles.form} onSubmit={handleSubmit}>
        <input
          required
          type="email"
          name="email"
          value={email}
          placeholder="Email"
          autoComplete="email"
          data-qa="sign-in-modal-email"
          onChange={({ target: { value } }) => setEmail(value)}
          className={cn(styles.input, { [styles.inputError]: loginError })}
        />
        <input
          required
          type="password"
          name="password"
          value={password}
          placeholder="Password"
          autoComplete="current-password"
          data-qa="sign-in-modal-password"
          onChange={({ target: { value } }) => setPassword(value)}
          className={cn(styles.input, { [styles.inputError]: loginError })}
        />
        <a
          target="_blank"
          href="/forgot-password"
          className={styles.link}
          onClick={trackClick}
        >
          Forgot Password?
        </a>
        <p
          className={cn(styles.error, {
            [styles.visible]: loginError || existingEmail,
          })}
        >
          {loginError
            ? "The email or password you entered is incorrect."
            : "You already have an account. Enter your password."}
        </p>
        <button
          type="submit"
          data-qa="sign-in-button"
          className={styles.button}
          disabled={!email || !password || loggingIn || loading}
        >
          {loggingIn || loading ? (
            <>
              SIGNING IN
              <LoadingDots />
            </>
          ) : (
            <>SIGN IN</>
          )}
        </button>
      </form>
      {createAccountLink && (
        <div className={styles.createAccountLinkContainer}>
          <a href={createAccountLink}>Create Account</a>
        </div>
      )}
    </Modal>
  );
};

SignInModal.propTypes = {
  onSuccess: PropTypes.func,
  existingEmail: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  createAccountLink: PropTypes.string,
  eventLabel: PropTypes.string, // additional analytics label.
  customCTA: PropTypes.node, // custom HTML that is rendered above the form inputs.
  customSideContent: PropTypes.node, // customSideContent. Styles will have height: 100% and width: 100% applied to it.
};

SignInModal.defaultProps = {
  onSuccess: null,
  existingEmail: "",
  createAccountLink: null,
  eventLabel: "Sign In Modal",
  customSideContent: (
    <picture>
      <img
        src="https://d2uvg1ukogcips.cloudfront.net/next-assets/public/chairs.jpg"
        alt="A beautiful office"
        className={styles.image}
      />
    </picture>
  ),
  customCTA: (
    <>
      <h1 className={styles.heading}>Welcome Back</h1>
      <h2 className={styles.subheading}>Sign in to proceed</h2>
    </>
  ),
};

export default SignInModal;
